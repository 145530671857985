.primary-button {
    margin: 20px !important;
}

.home-page {
    height: 100%;
}

.energy-schedule-header {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 10px 10px;
}
