.error-status {
  padding: 10% 5%;

  .icon-image {
    align-self: center;
    height: 80px;
    margin-bottom: 20px;
  }

  .error-status-text {
    text-align: center;
    align-self: center;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .contact-text {
    margin-top: 60%;
    font-size: 12px;
  }
}

.error-page {
  display: flex;
  flex-direction: column;

  .privacy-links {
    position: absolute;
    bottom: 25px;
    width: 90%;
    padding: 0 5px 30px 5px;
  }
}
