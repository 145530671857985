.utility-plan {
  background-color: white;
  height: 100vh;
  padding: 20px;

  @media (max-height: 785px) {
    height: 100%;
  }

  .arrow-button {
    position: absolute;
    left: 5%;
    top: 4%;
  }

  .utility-plan-header {
    padding-top: 60px;

    h1 {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      color: #00095B;
    }

    p {
      padding-top: 15px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: #00142E;
    }
  }

  .utility-plan-selectors {
    & > * {
      padding: 10px 35px; //TODO see if there is a better way to center the selects rather than just using padding
      position: relative;
    }

    .dropdown-header {
      padding: 0 0 10px 5px;
      font-size: 14px;
      font-weight: 400;
      line-height: 10.11px;
      letter-spacing: 0.01em;
      text-align: left;

    }

    .utility-provider, .utility-plan {
      justify-content: left;
      align-items: center;
      width: 277px;
      height: 100%;
      padding: 16px 32px 16px 16px;
      border: 1px solid #9A9A9A;
      border-radius: 8px;
      background: #F0F0F0;
      color: #00142E;
      text-overflow: ellipsis;
      overflow: hidden;

      @media (min-width: 410px) {
        width: 320px;
      }

      &:after {
        position: absolute;
        top: 56.5%;
        right: 15%;
        height: 10px;
        width: 10px;
        border-bottom: 1.4px solid #616161;
        border-right: 1.4px solid #616161;
        border-left: none;
        border-top: none;
        transform: rotate(315deg);
      }

      &.show {
        border-radius: 8px 8px 0 0;

        &:after {
          transform: rotate(45deg);
          top: 52%;
        }
      }
    }

    .dropdown-menu {
      &.show {
        border-radius: 8px 8px 0 0;
        border: none;
        inset: -2px auto auto 0px !important;
        padding: 0;
      }
    }

    .dropdown-item {
      display: flex;
      justify-content: left;
      align-items: center;
      width: 277px;
      height: 100%;
      padding: 17px 16px;
      border-left: 1px solid #9A9A9A;
      border-right: 1px solid #9A9A9A;
      background: #F0F0F0;
      color: #00142E;
      text-wrap: balance;

      @media (min-width: 410px) {
        width: 320px;
      }


      &:last-child {
        border-bottom: 1px solid #9A9A9A;
        border-radius: 0 0 8px 8px;
      }
    }
  }

  .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show { //TODO see if we can't get rid of this horrible selector
    border: 1px solid #9A9A9A;
    background: #F0F0F0;
    color: #00142E;
  }

  .plan-overview {
    p {
      color: #00142E;
      font-size: 14px;
      font-weight: 400;
      line-height: 10.11px;
      letter-spacing: 0.01em;
      text-align: left;
    }

    .plan-overview {
      padding: 20px 35px 0 35px;
      margin: 0;
    }

    .plan-type, .plan-hours {
      font-weight: 700;
      line-height: 24px;
    }

    .plan-type {
      width: 180px;

      + p {
        margin-left: 25px;
      }

      &:before {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 5px;
        position: relative;
        top: 5px;
      }

      &.peak {
        &:before {
          content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_5810_56421)'%3E%3Crect x='15.5' y='15.5' width='15' height='15' transform='rotate(180 15.5 15.5)' fill='%23F7D89B' stroke='%2300142E'/%3E%3Cpath d='M8.47271 2.86225C8.21236 2.6019 7.79025 2.6019 7.5299 2.86225L2.86323 7.52892C2.60288 7.78927 2.60288 8.21138 2.86323 8.47173C3.12358 8.73208 3.54569 8.73208 3.80604 8.47173L7.33464 4.94313V12.667C7.33464 13.0352 7.63311 13.3337 8.0013 13.3337C8.36949 13.3337 8.66797 13.0352 8.66797 12.667V4.94313L12.1966 8.47173C12.4569 8.73208 12.879 8.73208 13.1394 8.47173C13.3997 8.21138 13.3997 7.78927 13.1394 7.52892L8.47271 2.86225Z' fill='%2300142E'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_5810_56421'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        }
      }

      &.off-peak {
        &:before {
          content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_5810_56411)'%3E%3Crect x='0.5' y='0.5' width='15' height='15' fill='%23F0F0F0' stroke='%2300142E'/%3E%3Cpath d='M7.52729 13.1377C7.78764 13.3981 8.20975 13.3981 8.4701 13.1377L13.1368 8.47108C13.3971 8.21073 13.3971 7.78862 13.1368 7.52827C12.8764 7.26792 12.4543 7.26792 12.194 7.52827L8.66536 11.0569V3.33301C8.66536 2.96482 8.36689 2.66634 7.9987 2.66634C7.63051 2.66634 7.33203 2.96482 7.33203 3.33301V11.0569L3.80344 7.52827C3.54309 7.26792 3.12098 7.26792 2.86063 7.52827C2.60028 7.78862 2.60028 8.21073 2.86063 8.47108L7.52729 13.1377Z' fill='%2300142E'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_5810_56411'%3E%3Crect width='16' height='16' fill='white' transform='matrix(-1 0 0 -1 16 16)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        }
      }

    }

    .plan-details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .plan-hours {
      width: 100px;
      text-wrap: balance;
      margin-left: 13px;

      + p {
        margin-left: 13px;
      }
    }

    .hstack {
      padding: 20px 35px 0 35px;
    }

    .vstack {
      p {
        margin-bottom: 0;
      }
    }
  }

  .utility-plan-footer {
    position: relative;
    bottom: 10px;

    .utility-plan-contact-link {
      font-weight: 400;
      font-size: 12px;
      line-height: 14.52px;
      color: black;
      margin-top: 5%;
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .vstack {
      align-items: center;
    }

    .action-button {
      width: 235px;
      height: 40px;
      margin-top: 20px;
      border-radius: 10px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      border: 1px solid #020C58;


      &.primary {
        margin-top: 25%;
        background-color: #00095b;
        color: white;
      }

      &.secondary {
        background-color: white;
        color: #00095B;
      }
    }
  }

  .nav-tabs {
    padding-top: 15px;
  }

  .tab-content {

    .hstack {
      padding: 20px 15px 0 15px;
    }

    .plan-type {
      width: 185px;
    }
  }
}

