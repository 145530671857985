@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~bootstrap/scss/bootstrap';

@font-face {
    font-family: 'Inter';
    src: url('../public/fonts/Inter-VariableFont_slnt,wght.ttf') format('truetype');
    font-weight: 100 900; // supports font weights from 100 to 900
    font-style: normal;
}

$primary-font: 'Inter', sans-serif;
$primary-button-color: #00095b;

// Text colors
$primary-text-color: #000000;
$secondary-text-color: #616161;
$tertiary-text-color: #00095b;

// Background colors
$primary-background-color: #ffffff;
$gray-background-color: #f0f0f0;

// striped background
$stripe-blue-background: linear-gradient(
    45deg,
    #4284f2 25%,
    #6da6fa 25%,
    #6da6fa 50%,
    #4284f2 50%,
    #4284f2 75%,
    #6da6fa 75%,
    #6da6fa 100%
);

html,
body {
    height: 100%;
    font-family: $primary-font;
}

body {
    margin: 0;
    background-color: #f0f0f0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
