.edit-schedule-modal {
  background: #f0f0f0;
  .modal-dialog {
    margin: unset;

    .modal-content {
      border: none;
      border-radius: 0;

      .modal-body {
        padding: 0;
      }
    }

  }
}

