@use '../../styles';

.onboarding-content {
  padding: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  height: 100vh;

  .privacy-links {
    position: absolute;
    bottom: 3%;
    padding: 30px 5px 0 5px;
    width: 100%;
  }
}

.background-gray {
  background-color: #f0f0f0;
}

.title {
  margin-top: 40px;
  color: #00095b;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  @media (max-height: 700px) {
    margin-top: 10px;
  }
}

.icon-image {
  align-self: center;
  height: 48px;
}

.status-text {
  padding-top: 20px;
  text-align: center;
  align-self: center;
  color: #000000CC;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.tip-text {
  color: #00142e;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.nav-tabs .nav-link {
  border: none !important;
  color: #00142e !important;
}

.nav-tabs .nav-link.active {
  color: #00142e !important;
}

.nav-link.active {
  border-bottom: 5px solid #00095b !important;
}

.scroll-content {
  overflow-y: auto;
  height: calc(100vh - 130px); /* Subtract the height of the footer */
  background-color: white;
}

.image-container {
  position: relative;

  img {
    width: 100%;
  }

  .text {
    position: absolute;
    width: 150px;
    text-align: right;
    top: 10px;
    right: 10px;
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;

    span {
      display: block;
    }
  }
}

.sticky-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 130px;
  background-color: #f0f0f0;
  color: #fff;
  text-align: center;
}

.continue-button {
  font-weight: 700 !important;
  background-color: styles.$primary-button-color !important;
  border-color: styles.$primary-button-color !important;
}

.edit-button {
  font-weight: 700 !important;
  background-color: white !important;
  border-color: #00095b !important;
  color: #00095b !important;
}

.contact-link {
  position: absolute;
  font-weight: 400;
  font-size: 12px;
  color: black;
  bottom: 5%;
  text-align: center;
}

.footer-contact {
  font-weight: 400;
  font-size: 12px;
  color: black;
}
