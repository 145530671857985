@use '../../styles';

.login-container {
  height: 100vh;
  background-color: styles.$gray-background-color;
  overflow-y: hidden;
  overflow-x: hidden;
}

.image-section {
  height: 45vh;
  opacity: 50%;
  z-index: 0;
  overflow-y: hidden;

  img {
    width: 100%;
  }
}

.ford-logo {
  justify-content: center;
  margin: 5% auto auto auto;
  height: 50px;
  width: 30%;
}

.login-section {
  margin-top: 5%;
  z-index: 10;

  .privacy-links {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 2%;
  }
}

.login-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #00142e;
}

.login-button {
  font-weight: 700;
  width: 30%;
  background-color: styles.$primary-button-color;
  border-color: styles.$primary-button-color;
}

.contact-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 8%;
}

.app-version {
  position: absolute;
  bottom: 0;
  right: 1px;
  margin: 0 2px 2px 0;
  font-size: 10px;
  color: gray;
}
