.weekly-transfer-goal {
  padding: 0 10px;
}

.weekly-transfer-goal-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 12.5px;
  text-align: left;
}

.weekly-transfer-goal-progress-bar {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 10px 0;
  padding-right: 20px
}

.weekly-transfer-goal-label {
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  letter-spacing: 0.02em;
  text-align: left;
}

.weekly-transfer-goal-instruction-text {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  letter-spacing: 0.02em;
  text-align: left;
  padding: 10px 15px 0 60px;


  &:before {
    content: url('../../assets/icons/Ready_to_go_icon.svg');
    position: absolute;
    top: 10px;
    left: 20px;
  }
}
