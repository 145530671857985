.hide {
  display: none;
}

.edit-schedule {
  display: flex;
  background-color: white;
  height: 100vh;
  padding-top: 5%;

  .arrow-button {
    height: 20px;
    width: 18px;
    position: absolute;

    &.forward {
      right: 5%;
      rotate: 180deg;
    }

    &.back {
      left: 5%;
    }
  }

  .title {
    margin: 0 0 25px 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
  }

  .status-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    padding: 0 10px;
    margin-bottom: 1.5rem
  }

  .footer {
    .hstack {
      border-top: 1px solid #E0E0E0;
    }
    div {
      position: absolute;
      height: 75px;
      width: calc(100% - 32px);
      bottom: 5%;
    }

    .text-row-2 {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.94px;
      letter-spacing: 0.02em;
      color: #9A9A9A;
      padding: 20px 0 10px 2px;
      width: 45%;

      &.left {
        text-align: left;
      }

      &.right {
        text-align: right;
        width: 45.5%;
        position: absolute;
        right: 16px;
      }
    }
  }

  .time-picker {
    font-size: 22px;
    font-weight: 400;
    line-height: 26.63px;
    letter-spacing: 0.02em;
    margin: 25px 0;
    color: #00095B;
    float: left;

    &.weekend {
      margin: 50px 0 25px 0;
    }

    small {
      font-size: 12px;
      font-weight: 300;
      line-height: 12.72px;
      letter-spacing: -0.28841379284858704px;
      float: left;
      color: #616161;
      margin-top: 5px;

    }

    span {
      position: relative;
      left: 10px;
      top: 36px;
    }
  }

  label {
    display: block;
  }

  input {
    margin: 6.5px 5px 0 0;
    border-width: 1.5px;
    border-color: #00095B;
    height: 38px;
    padding: 5px;

    &.end-time {
      margin-left: 5px;
    }

    &.invalid-input {
      border-color: red;
    }
  }

  .weekend-schedule-toggle-container {
    span {
      color: #616161;
      float: left;
      position: relative;
      top: 27px;
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;
      margin: 20px 15px 10px 0;
      float: right;
    }

    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    input:checked + .slider {
      background-color: #00095B;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #00095B;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }

  .pct-disclaimer {
    border-top: 1px solid #E0E0E0;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #9A9A9A;

    p {
      margin-top: 15px;
      padding: 0 20px
    }

  }
}

.edit-schedule-confirmation-modal {
  background: var(--Color-Icon-NoInv-OnSurf-Neutral-Dark-Medium, #00000080);

  .btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 100%;
  }
}
