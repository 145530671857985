.save-button-sticky-container {
  order: 4;
  margin: 0 25px;
  position: sticky;
  bottom: -72.5px;
  padding: 20px 0;
  background: white;
  border-top: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;

  .save-button {
    height: 40px;
    width: 100%;
    border-radius: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    background-color: #00095b;
    color: white;


    &:disabled {
      border: 1px solid #F0F0F0;
      background-color: #F0F0F0;
      color: #9A9A9A;
      font-size: 14px;
      font-weight: 700;
      line-height: 16.94px;
      letter-spacing: -0.02em;
      text-align: center;

    }
  }
}
