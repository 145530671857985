.onboarding-container {
  background: #F0F0F0;

  .privacy-links {
    background: white;
  }

  &.on-board-ready {
    .privacy-links {
      position: relative;
      bottom: -50px;
      background: none;
    }
  }
}