@use '../../styles';

.menu-toggle-button {
  margin-top: 5%;
  @media (max-height: 700px) {
    margin-top: 2%;
  }
}

.menu-button-icon {
  width: 30px;
  height: 30px;
  background-position: left;
  background-repeat: no-repeat;
}

.menu-button {
  margin-left: -12px;
  display: flex;
  align-items: start;

  .button-icon {
    width: 30px;
    height: 30px;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 5px;
  }
}

.offcanvas {
  width: 80% !important;
  height: 100vh;
  color: styles.$tertiary-text-color !important;
}

.offcanvas-title {
  margin-left: 14px;
}

.menu-item {
  margin-left: 14px;
  line-height: 60px;
  display: inline-block;

  &__text {
    display: inline-block;
  }

  a {
    color: styles.$tertiary-text-color;
    text-decoration: none;
  }

  .chevron-right-icon {
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-left: 30px;
  }
}

.side-menu-divider {
  color: gray;
}

.version-number {
  margin: 0 0 2% 30px;
  font-size: 12px;
}


