@use '../../styles';

$ICONS: '../../assets/icons';

// Gradient colors
$gradient-gray: linear-gradient(180deg, rgba(97, 97, 97, 0.14) -20.33%, #616161 100%);
$gradient-navy: linear-gradient(180deg, rgba(0, 20, 46, 0.6) -4%, #00142e 100%);
$gradient-blue: linear-gradient(180deg, rgba(66, 132, 242, 0.8) 6.49%, rgba(0, 9, 91, 0.95) 100%);
$gradient-green: linear-gradient(
                180deg,
                rgba(77, 149, 82, 0.35) -11.45%,
                #257436 99.98%,
                rgba(77, 149, 82, 0.52) 100%
);
$gradient-orange: linear-gradient(180deg, #696969 -20.33%, #fca656 100%);

.main-container {
  padding: 0;
  background-color: styles.$gray-background-color;
}

.dynamic-background {
  height: 330px;

  @media (max-height: 700px) {
    height: 40vh;
  }

  &.not-plugged-in {
    background: $gradient-gray;
  }

  &.ready-to-go {
    background: $gradient-navy;
  }

  &.waiting-to-charge {
    background: $gradient-navy;
  }

  &.charging {
    background: $gradient-blue;
  }

  &.powering-home {
    background: $gradient-green;
  }

  &.outage-detected {
    background: $gradient-orange;
  }
}

.side-menu-toggle {
  margin-left: -4%;
}

.summary-stack {
  color: white;
  font-style: normal;
  margin-left: 5%;
}

.summary-stack-row {
  margin-bottom: 15px;

  @media (max-height: 700px) {
    margin-bottom: 1%;
  }
}

.plug-status-text {
  color: white;
  font-weight: 600;
  font-style: normal;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  margin-top: 20px;

  @media (max-height: 700px) {
    margin-top: 5px;
  }
}

.plug-status-icon {
  width: 26px;
  margin-right: 5px;
  background-position: center;
  background-repeat: no-repeat;

  &.charging-icon {
    background-image: url($ICONS + '/STATUS/Charging_icon.svg');
  }

  &.waiting-to-charge-icon {
    background-image: url($ICONS + '/STATUS/Waiting_to_charge_icon.svg');
  }

  &.not-plugged-in-icon {
    background-image: url($ICONS + '/STATUS/Not_plugged_in_icon.svg');
  }

  &.ready-to-go-icon {
    background-image: url($ICONS + '/STATUS/Ready_to_go_icon.svg');
  }

  &.powering-home-icon {
    width: 40px;
    background-image: url($ICONS + '/STATUS/Powering_home2_icon.svg');
  }

  &.outage-detected-icon {
    height: 29px;
    background-size: 100%;
    background-image: url($ICONS + '/STATUS/Generic_warning_icon.svg');
  }
}

.charge-complete-container {
  height: 10vh;
}

.event-completion-text {
  font-weight: 400;
  font-size: 14px;
}

.outage-detected-text {
  font-weight: 900;
  font-size: 20px;
  line-height: 16px;
}

.time-text {
  font-weight: 900;
  font-size: 50px;
  line-height: 55px;
}

.time-remain-text :nth-child(2n) {
  font-size: 18px;
}

.time-remain-text :nth-child(n + 2) {
  margin-left: 4px;
}

.state-of-charge {
  margin-top: 5px;

  .battery-level-text {
    color: white;
    font-weight: 900;
    font-size: 52px;
    line-height: 52px;
    display: inline-block;
  }

  .battery-icon {
    display: inline-block;
    position: relative;
    top: -25px;
    left: -20px;
  }

  @media (max-height: 700px) {
    margin-top: 5%;
  }
}

.cropped-container {
  position: absolute;
  top: 70px;
  right: 0px;
  width: 260px;
  height: 288px;
  overflow-x: hidden;

  @media (max-height: 700px) {
    top: 10px;
  }
}

.contact-button {
  border: 1px solid white;
  background-color: black;
  color: white;
}

.vehicle-img {
  position: absolute;
  bottom: 0;
  right: -35px;
  opacity: 1;
}
