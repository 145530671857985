.modal-dialog {
    .modal-content {
        background-color: black;
        color: white;
        text-align: center;
        border-radius: 20px;
        font-size: 16px;
        font-weight: 400;

        .modal-header {
            border-bottom: none;
            border-radius: 20px;
            padding: 10px;
            color: white;
            font-size: 18px;
            font-weight: 700;

            span {
                margin: auto;
            }

            .btn-close {
                position: absolute;
                right: 4%;
            }
        }

        .option-button {
            width: 20%;
            background-color: #d9d9d9;
            border-color: #d9d9d9;
        }

        .contact-button {
            border: 1px solid white;
            background-color: black;
            color: white;
            font-size: 16px;
            font-weight: 700;
            margin-top: 20px;

            a {
                color: white;
                text-decoration: none;
            }
        }

    }
}
