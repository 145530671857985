.charge-settings {
    padding: 0 10px;
    margin-top: 20px;

    @media (max-height: 700px) {
        margin-top: 5%;
    }
}

.card {
    border: none !important;
    border-radius: 15px !important;
}

.card-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
}

.col-card {
    width: 50%;
    padding: 10px !important;
    flex-grow: 1;
    align-self: stretch;
}

.rounded-card {
    background-color: white;
    border: none;
    border-radius: 15px;
    border-width: 0;
}

.edit-button {
    float: right;
    height: 20px;
    width: 18px;
}

.text-row-1 {
    line-height: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.text-row-2 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    .text-sm {
        font-size: 22px;
    }
}

.text-row-3 {
    color: #616161;
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
}

.overflow-text {
    text-overflow: ellipsis;
}

.reserve-number {
    font-size: 15px;
    font-weight: 600;
    color: #616161;

    span {
        font-size: 10px;
    }
}
