.backup-power-tile {
  padding: 0 10px;
  margin-top: 20px;

  @media (max-height: 700px) {
    margin-top: 5%;
  }

  .text-row-2 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
  }

  .text-row-3 {
    color: #616161;
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
  }
}

.card {
  border: none !important;
  border-radius: 15px !important;
}

.card-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.col-card {
  width: auto;
  padding: 10px !important;
  flex-grow: 1;
  align-self: stretch;
}

.rounded-card {
  background-color: white;
  border: none;
  border-radius: 15px;
  border-width: 0;
}

.backup-power-section {
  width: 50%;
}

.backup-power-tile-header {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-wrap: nowrap;
}

.edit-button {
  position: relative;
}

.stop-transfer-container {
  .action-button {
    height: 40px;
    width: 95%;
    margin-top: 10px;
    margin-right: 5px;
    float: right;
    border-radius: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    background-color: #00095b;
    color: white;
  }

  .btn:disabled {
    width: 95%;
    background-color: #ebebeb;
    border-color: #ebebeb;
    color: #9a9a9a;
  }

  .modal-content {
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 20px;

    .modal-header {
      border-bottom: none;
      border-radius: 20px;
      padding: 10px;
      color: white;

      span {
        margin: auto;
      }

      .btn-close {
        position: absolute;
        right: 4%;
      }
    }

    .option-button {
      width: 20%;
      background-color: #d9d9d9;
      border-color: #d9d9d9;
    }
  }

  .alert-bottom {
    position: absolute;
    margin: 6px auto;
    bottom: 0;
    left: 0;
    width: 100%;
    color: white;
    font-size: 14px;
    border-radius: 20px;
    border-color: #202020;
    background-color: #202020;
  }

}
