.edit-range-reserve-modal {
  .modal-dialog { //todo need to put a wrapper class around so we don;t affect every modal
    margin: unset;

    .modal-content {
      border: none;
    }

    .modal-body {
      padding: unset;
      background: white;

      .privacy-links {
        background: white;
      }
    }
  }
}

.scrollable-content {
  overflow-y: auto;
  max-height: 100vh;
  height: calc(100vh - 154.5px);
}


.edit-range-reserve {
  background-color: white;
  height: calc(100vh - 72.5px);
  max-height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .edit-range-reserve-header {
    margin: 0 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 20%;
    order: 1;


    .arrow-button-back {
      position: relative;
      //left: 20px;
      top: 20px;
      //z-index: 1000;
    }

    .edit-range-reserve-header-title {
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      color: #00095B;
    }

    .edit-range-reserve-header-text {
      margin-top: 15%;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      color: #000000CC;

    }
  }

  .edit-range-reserve-content {
    margin: 25px;
    flex: 1;
    order: 2;

    .edit-range-reserve-selector {
      display: flex;
      justify-content: center;

      .range-reserve-minus,
      .range-reserve-plus {
        position: relative;
        width: 33px;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: contain;
        margin-top: 5px;

        &::before {
          content: '';
          display: block;
          width: 17px;
          height: 44px;
          background-size: contain;
          position: absolute;
          z-index: 1;
        }
      }

      .range-reserve-minus {
        margin-right: 15px;

        &::before {
          background: url('../../assets/icons/Minus_icon.svg') no-repeat center center;
        }
      }

      .range-reserve-plus {
        margin-left: 15px;

        &::before {
          background: url('../../assets/icons/Plus_icon.svg') no-repeat center center;
        }
      }

      p {
        font-size: 36px;
        font-weight: 700;
        line-height: 43.57px;
        letter-spacing: 0.02em;
        text-align: center;
        color: black;
      }
    }

    .edit-range-reserve-slider {
      display: flex;
      padding-top: 10%;

      input[type="range"] {
        flex: 0 0 70%;
        margin: 0 10px;
      }

      input[type="range"]::-webkit-slider-runnable-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        background: linear-gradient(to right, #00095B 0%, #00095B var(--value), #EBEBEB var(--value), #EBEBEB 100%);
        border-radius: 5px;
      }

      input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        background: white;
        border: 5px solid #00095B;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
        top: -8.5px; /* Center thumb vertically */
      }

      input[type="range"]:hover {
        opacity: 1; /* Full opacity on hover */
      }

      .edit-range-reserve-slider input[type="range"]::-moz-range-track {
        width: 100%;
        height: 8px;
        background: transparent;
      }

      .edit-range-reserve-slider input[type="range"]::-moz-range-thumb {
        width: 20px;
        height: 20px;
        background: #fff;
        border: 2px solid #000;
        border-radius: 50%;
        cursor: pointer;
      }


      .edit-range-reserve-slider-start-label,
      .edit-range-reserve-slider-end-label {
        flex: 0 0 5%;
        text-align: center;
        text-wrap: nowrap;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;
        letter-spacing: 0.02em;
        color: #000000;
        margin-top: 3px;
      }

      .edit-range-reserve-slider-end-label {
        text-align: right;
      }
    }

  }

  .edit-mode-body {
    border-top: 1px solid #E0E0E0;
    margin: 0 25px;
    flex: 2.5;
    order: 3;

    .edit-mode-toggle-container { //TODO put lines 194 to 265 in some kind of toggle css class
      span {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000000CC;
        float: left;
        position: relative;
        top: 27px;
      }

      .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
        margin: 20px 15px 10px 0;
        float: right;
      }

      /* Hide default HTML checkbox */
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
      }

      .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }

      input:checked + .slider {
        background-color: #00095B;
      }

      input:focus + .slider {
        box-shadow: 0 0 1px #00095B;
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }

      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }

      .slider.round:before {
        border-radius: 50%;
      }
    }

    .edit-mode-text {
      display: inline-block;
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: var(--Color-Text-Inv-OffSurf-Neutral-Medium, #00000080);
      padding-top: 20px;
    }
  }

}
