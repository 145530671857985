@use '../../styles';

.energy-schedule {
  padding: 0 10px;
}

.energy-schedule-header {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin: 0 0 10px 10px;
}

.edit-button {
  position: relative;
}

.card {
  border: none !important;
  border-radius: 15px !important;
}

.rounded-card {
  background-color: white;
  border: none;
  border-radius: 15px;
  border-width: 0;
}

.timeline-card {
  margin-top: 20px;
  height: 300px;
}

.thick-text {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 22px;
  margin: 10px 0;
}

.edit-button {
  float: right;
  height: 20px;
  width: 18px;
}

.card-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.no-schedule {
  height: 120px;
  width: 100%;
  margin: 20px 0;
  background-color: #ebebeb;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;

  span {
    position: absolute;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #6f6a6a;
  }
}

.timeline {
  margin-left: -15px;
  overflow-x: scroll !important;
  overflow-y: hidden;

  .now-label {
    position: absolute;
    top: 125px;
    left: 5px;
    font-size: 13px;
  }
}

#chart {
  overflow-x: scroll !important;
}

.apexcharts-toolbar {
  display: none !important;
}

.apexcharts-legend {
  display: none !important;
}

.skip-schedule-container {
  .action-button {
    height: 40px;
    width: 45%;
    margin-top: 10px;
    margin-right: 5px;
    float: right;
    border-radius: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    background-color: #00095b;
    color: white;
  }

  .btn:disabled {
    background-color: #ebebeb;
    border-color: #ebebeb;
    color: #9a9a9a;
  }

  .modal-content {
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 20px;

    .modal-header {
      border-bottom: none;
      border-radius: 20px;
      padding: 10px;
      color: white;

      span {
        margin: auto;
      }

      .btn-close {
        position: absolute;
        right: 4%;
      }
    }

    .option-button {
      width: 20%;
      background-color: #d9d9d9;
      border-color: #d9d9d9;
    }
  }

  .alert-bottom {
    position: absolute;
    margin: 6px auto;
    bottom: 0;
    left: 0;
    width: 100%;
    color: white;
    font-size: 14px;
    border-radius: 20px;
    border-color: #202020;
    background-color: #202020;
  }
}

.pct-error-modal-button {
  height: 40px;
  width: 45%;
  margin-top: 10px;
  margin-right: 5px;
  border-radius: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  background-color: #9A9A9A;
  color: white;
}
