.onboard-ready {
  .onboard-ready-modal .modal-content {
    background-color: white;
    color: black;
    border-radius: 20px;
    font-size: 14px;

    .modal-header {
      border-bottom: none;
      border-radius: 20px;
      padding: 20px 20px 20px 0;
      font-weight: 600;
      color: #00142e;

      span {
        margin: auto;
      }

      .btn-close {
        position: absolute;
        right: 4%;
      }
    }

    .modal-body {
      padding: 0 40px 20px 40px;
      font-weight: 400;

      p {
        text-align: left;
      }
    }

    .option-button {
      width: 20%;
      background-color: #d9d9d9;
      border-color: #d9d9d9;
    }
  }

  .arrow-button {
    position: absolute;
    left: 5%;
  }

  .sticky-footer {
    bottom: 25px;
  }

  .footer-contact {
    margin-top: 15px;
  }

  .privacy-links {
    position: relative;
    bottom: -60px;
  }
}
