.privacy-links {
  padding: 30px 5px;
  display: flex;
  justify-content: space-around;

  a {
    font-size: 12px;
    font-weight: 400;
    line-height: 12.5px;
    text-align: center;
    color: #A4A4A4;
  }
}