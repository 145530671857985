.energy-flow-img {
  opacity: 0.5;
  position: absolute;
  right: 10%;
}

.bar-container {
  position: absolute;
  bottom: 105px;
  right: 35px;
  overflow: hidden;
  height: 158px;
  width: 56px;
}

.discharge-rate {
  width: 58px;
  height: 27px;
  border-radius: 22px 22px 22px 22px;
  background: #54874D;
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: -0.40799999237060547px;
  text-align: left;
  color: white;
  position: absolute;
  bottom: 0;
  z-index: 1; //z-index in model is set by default to a variable, which seems to get a value of 1055, so this value needs to be lower than that, but higher than vehicle image
  display: flex;
  justify-content: center;
  align-items: center;

}

.energy-diagram {
  position: absolute;
  bottom: 0;
}

.flow-animation {
  position: absolute;
  background-color: transparent;
  overflow: hidden;
  left: 25px;
  width: 6px;

  &-top {
    @extend .flow-animation;
    height: 32px;
    top: 38px;
  }

  &-bottom {
    @extend .flow-animation;
    height: 44px;
    top: 114px;
  }
}

.flow {
  position: absolute;
  top: 0;
  left: 1px;
  height: 15px;
  width: 4px;
  overflow: hidden;
  border-radius: 25px;

  &-down {
    @extend .flow;
    background: linear-gradient(rgba(66, 132, 242, 0.8) 100%, white 1%);
    animation: flowing-down 3s linear infinite;
  }

  &-up-green {
    @extend .flow;
    background: linear-gradient(#519756 100%, #89b48e 50%);
    animation: flowing-up 3s linear infinite;
  }

  &-up-orange {
    @extend .flow;
    background: linear-gradient(#f6a458 100%, #f4d9bf 50%);
    animation: flowing-up 4s linear infinite;
  }
}

@keyframes flowing-down {
  0% {
    top: 0%;
  }
  100% {
    top: 120%;
  }
}

@keyframes flowing-up {
  0% {
    top: 100%;
  }
  100% {
    top: -20%;
  }
}
