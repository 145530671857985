.charge-modal-container {
  .action-button {
    height: 40px;
    width: 45%;
    margin-top: 10px;
    margin-right: 5px;
    float: right;
    border-radius: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: white;

    &.backup-power {
      width: 97%;
    }

    &.ford-energy-rewards {
      float: left;
    }

    &:disabled {
      border: 1px solid #F0F0F0;
      background-color: #F0F0F0;
      color: #9A9A9A;
      font-size: 14px;
      font-weight: 700;
      line-height: 16.94px;
      letter-spacing: -0.02em;
      text-align: center;

    }

    &.start:not(:disabled) {
      background-color: #4580F3;
      border: 1px solid #4580F3;
    }

    &.stop:not(:disabled) {
      border: 1px solid #E73F34;
      background-color: #E73F34;
    }
  }

  .alert-bottom {
    position: absolute;
    margin: 6px auto;
    bottom: 0;
    left: 0;
    width: 100%;
    color: white;
    font-size: 14px;
    border-radius: 20px;
    border-color: #202020;
    background-color: #202020;
  }


}