.pct-not-set {
  &.showClose {
    color: #000000CC;

    .close-button {
      position: absolute;
      right: 4%;
      padding-top: 20px;
      rotate: 45deg;
      height: 40px;
      width: 20px;
    }
  }
}
