.edit-target-charge-modal {
  .modal-dialog {
    margin: unset;

    .modal-content {
      border: none;
    }

    .modal-body {
      padding: unset;
      background: white;

      .privacy-links {
        background: white;
      }
    }
  }
}

.edit-target-charge {
  background-color: white;
  height: calc(100vh - 154.5px);

  .edit-target-charge-header {
    margin: 0 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 20%;


    .arrow-button-back {
      position: absolute;
      left: 20px;
      top: 80px;
    }

    .edit-target-charge-header-title {
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      color: #00095B;
    }

    .edit-target-charge-header-text {
      margin-top: 15%;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      color: #000000CC;

    }
  }

  .edit-target-charge-content {
    margin: 25px;

    .edit-target-charge-selector {
      display: flex;
      justify-content: center;

      p {
        width: 120px;
      }

      .target-charge-minus,
      .target-charge-plus {
        position: relative;
        width: 33px;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: contain;
        margin-top: 5px;

        &::before {
          content: '';
          display: block;
          width: 17px;
          height: 44px;
          background-size: contain;
          position: absolute;
          z-index: 1;
        }
      }

      .target-charge-minus {
        margin-right: 15px;

        &::before {
          background: url('../../assets/icons/Minus_icon.svg') no-repeat center center;
        }
      }

      .target-charge-plus {
        margin-left: 15px;

        &::before {
          background: url('../../assets/icons/Plus_icon.svg') no-repeat center center;
        }
      }

      p {
        font-size: 36px;
        font-weight: 700;
        line-height: 43.57px;
        letter-spacing: 0.02em;
        text-align: center;
        color: black;
      }
    }

    .edit-target-charge-slider {
      display: flex;
      padding-top: 10%;

      input[type="range"] {
        flex: 0 0 70%;
        margin: 0 10px;
        //-webkit-appearance: none; /* Remove default appearance */
        //width: 100%; /* Full width */
        //height: 3px; /* Track height */
        //background: green; /* Track background */
        //border-radius: 5px; /* Rounded corners */
        ////outline: none; /* Remove outline */
        ////opacity: 0.7; /* Slightly transparent */
        ////transition: opacity .2s; /* Transition for hover effect */
      }

      input[type="range"]::-webkit-slider-runnable-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        background: linear-gradient(to right, #00095B 0%, #00095B var(--value), #EBEBEB var(--value), #EBEBEB 100%);
        border-radius: 5px;
      }

      input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        background: white;
        border: 5px solid #00095B;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
        top: -8.5px; /* Center thumb vertically */
      }

      input[type="range"]:hover {
        opacity: 1; /* Full opacity on hover */
      }

      .edit-target-charge-slider input[type="range"]::-moz-range-track {
        width: 100%;
        height: 8px;
        background: transparent;
      }

      .edit-target-charge-slider input[type="range"]::-moz-range-thumb {
        width: 20px;
        height: 20px;
        background: #fff;
        border: 2px solid #000;
        border-radius: 50%;
        cursor: pointer;
      }

      .edit-target-charge-slider-start-label,
      .edit-target-charge-slider-end-label {
        flex: 0 0 5%;
        text-align: center;
        text-wrap: nowrap;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;
        letter-spacing: 0.02em;
        color: #000000;
        margin-top: 3px;
      }

      .edit-target-charge-slider-end-label {
        text-align: right;
      }
    }

    .edit-target-charge-location-text {
      margin-top: 10%;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      color: #00142E;
      opacity: 65%;
    }
  }
}